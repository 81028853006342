import { Button, Card, Heading, Pane, Spinner, majorScale } from 'evergreen-ui';
import React, { FC, useEffect } from 'react';
import MultiFileUploader from '../MultiFileUploader/MultiFileUploader';
import WysiwygEditor from '../WysiwygEditor/WysiwygEditor';

interface PostCommentBoxProps {
  show: boolean
  isPosting: boolean
  postComment: (comment: string) => void
  setCommentUploads: (uploads: File[]) => void
  postButtonText: string
  placeholderText: string
  isPostSuccess: boolean
}

const PostCommentBox: FC<PostCommentBoxProps> = (
  props: PostCommentBoxProps,
) => {
  const [comment, setComment] = React.useState('')

  useEffect(() => {
    if (props.isPostSuccess) {
      setComment('')
    }
  }, [props.isPostSuccess])

  if (!props.show) {
    return null
  }

  return (
    <Pane marginY={majorScale(4)}>
        <Card
          border="default"
          padding={majorScale(2)}
          display="flex"
          flexDirection="column"
          background="white"
          borderRadius={10}
          marginBottom={majorScale(2)}
        >
          <Heading>Post a Comment</Heading>
          <Pane marginBottom={majorScale(2)}></Pane>
          <WysiwygEditor
            initialValue={comment}
            onUpdateContent={(newContent: string) => setComment(newContent)}
          />
          <Pane marginBottom={majorScale(2)}></Pane>
          <Heading size="400">Upload images</Heading>
          <Pane marginTop={majorScale(1)}>
            <MultiFileUploader
              onChange={(files: File[]) => props.setCommentUploads(files)}
            ></MultiFileUploader>
          </Pane>
        </Card>
        <Pane marginBottom={majorScale(2)}></Pane>
      <Pane textAlign="right">
        <Button
          marginLeft="auto"
          disabled={props.isPosting || comment === ''}
          type="button"
          appearance="primary"
          onClick={() => {
            props.postComment(comment)
          }}
          color="white"
        >
          {props.isPosting ? (
            <Spinner size={majorScale(2)} color="white"></Spinner>
          ) : (
            <strong>{props.postButtonText}</strong>
          )}
        </Button>
      </Pane>
    </Pane>
  )
}

export default PostCommentBox
