import {
  Button,
  EditIcon,
  majorScale,
  Menu,
  MoreIcon,
  Pane,
  Popover,
  Position,
  TrashIcon,
} from 'evergreen-ui'
import { FC } from 'react'

interface EditableContextMenuProperties {
  editable?: boolean
  edit?: () => void
  deletable?: boolean
  delete?: () => void
}

const EditableContextMenu: FC<EditableContextMenuProperties> = (
  props: EditableContextMenuProperties,
) => {

  // Backwards compatability with previous state
  if (props.editable === undefined) {
    props.editable = true
  }

  return (
    <Pane display="flex" alignItems="center">
      <Popover
        position={Position.BOTTOM_RIGHT}
        content={
          <Menu>
            {props.editable && (<Menu.Item
              icon={<EditIcon />}
              onClick={() => {props.edit && props.edit()}}
              padding={majorScale(1)}
            >
              Edit
            </Menu.Item>)}
            {props.deletable && (<Menu.Item
              icon={<TrashIcon />}
              onClick={() => {props.delete && props.delete()}}
              padding={majorScale(1)}
            >
              Delete
            </Menu.Item>)}
            <Menu.Divider />
          </Menu>
        }
      >
        <Button
          padding={majorScale(2)}
          display="flex"
          justifyContent="center"
          alignItems="center"
          appearance="minimal"
        >
          <MoreIcon color='#7A8A99' />
        </Button>
      </Popover>
    </Pane>
  )
}

export default EditableContextMenu
